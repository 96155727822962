<!-- @format -->
<template>
  <b-card title="品牌添加">
    <validation-observer ref="createProdBrand">
      <b-form>
        <b-row md="12">
          <b-col md="4">
            <b-form-group label-align="center" label="品牌名称" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <validation-provider #default="{ errors }" name="品牌名称" rules="required">
                <b-form-input v-model="editData.title" :state="errors.length > 0 ? false : null" placeholder="输入品牌名" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌网站" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <ValidationProvider #default="{ errors }" name="品牌网站" rules="required|url">
                <b-form-input v-model="editData.url" :state="errors.length > 0 ? false : null" placeholder="输入品牌网站" />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- 上传图片 -->
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌logo" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="editData.logo"
                    :text="avatarText(editData.fullName)"
                    size="90px"
                    rounded
                  />
                </template>
                <h4 class="mb-1">
                  {{ editData.fullName }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer"
                    >
                    <span class="d-none d-sm-inline">Update</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-align="center" label="品牌状态" label-cols="1" label-cols-md="3" content-cols="6" content-cols-md="8">
              <b-form-radio-group
                v-model="editData.status"
                :options="[
                  {
                    text: '启用',
                    value: 1,
                  },
                  {
                    text: '禁用',
                    value: 0,
                  },
                ]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { BCard, BMedia, BAvatar, } from 'bootstrap-vue'
import { reactive, ref, onUnmounted, getCurrentInstance } from '@vue/composition-api'
// 获取当前页面实例
import { required, url } from '@core/utils/validations/validations'

import store from '@/store'
import BrandManagerModule from '@/views/GeneralManagement/ProductManager/Brand/BrandManagerModule'
import { useRouter } from '@core/utils/utils'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import axiosIns from '@/libs/axios'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'BrandCreate',
  components: {
    BCard, BMedia, BAvatar,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'brand-module'
    const { router } = useRouter()
    const { proxy } = getCurrentInstance()

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, BrandManagerModule)
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })
    const isRequest = ref(false)
    const editData = reactive({
      title: '',
      url: '',
      status: 1,
    })
    const createProdBrand = ref(null)

    const validationForm = () => {
      store.dispatch('brand-module/createInvoices', { ...editData }).then(response => {
        if (response.code == 0) {
          proxy.$bvToast.toast('添加成功', {
            title: '提示',
            variant: 'success',
            solid: true,
          })
          setTimeout(() => {
            isRequest.value = false
            router.back()
          }, 2000)
        } else {
          proxy.$bvToast.toast(response.msg, {
            title: '提示',
            variant: 'danger',
            solid: true,
          })
        }
      })
      // createProdBrand.value
      //   .validate()
      //   .then(res => {
      //     if (res) {

      //     } else {
      //       isRequest.value = false
      //     }
      //   })
      //   .catch(() => {
      //     isRequest.value = false
      //     console.log('validationForm error')
      //   })
    }

    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      const formData = new FormData()
      formData.append('image', refInputEl.value.files[0])
      axiosIns.post('upload/uploadAvatar', formData).then(res => {
        if (res.code == 0) {
          editData.logo = process.env.VUE_APP_COM_URL + res.data.url
        } else {
          proxy.$bvToast.toast('上传失败', {
            title: '提示',
            variant: 'danger',
            solid: true,
            autoHideDelay: 2000,
          })
        }
      })
    })

    return {
      avatarText,
      validationForm,
      refInputEl,
      previewEl,
      inputImageRenderer,

      editData,
      createProdBrand,

      isRequest,
      required,
      url,
    }
  },
}
</script>

<style scoped></style>
