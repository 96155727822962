var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"品牌添加"}},[_c('validation-observer',{ref:"createProdBrand"},[_c('b-form',[_c('b-row',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"品牌名称","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('validation-provider',{attrs:{"name":"品牌名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入品牌名"},model:{value:(_vm.editData.title),callback:function ($$v) {_vm.$set(_vm.editData, "title", $$v)},expression:"editData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"品牌网站","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('ValidationProvider',{attrs:{"name":"品牌网站","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"输入品牌网站"},model:{value:(_vm.editData.url),callback:function ($$v) {_vm.$set(_vm.editData, "url", $$v)},expression:"editData.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"品牌logo","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.editData.logo,"text":_vm.avatarText(_vm.editData.fullName),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.editData.fullName)+" ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"input":_vm.inputImageRenderer}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Update")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1)],1)])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-align":"center","label":"品牌状态","label-cols":"1","label-cols-md":"3","content-cols":"6","content-cols-md":"8"}},[_c('b-form-radio-group',{attrs:{"options":[
                {
                  text: '启用',
                  value: 1,
                },
                {
                  text: '禁用',
                  value: 0,
                } ]},model:{value:(_vm.editData.status),callback:function ($$v) {_vm.$set(_vm.editData, "status", $$v)},expression:"editData.status"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isRequest},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 提交 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }